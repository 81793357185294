.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.title {
  font-weight: lighter;
  color: var(--main-color);
  font-size: 16px;
  width: fit-content;
}

.subtitle {
  color: #949494;
  font-size: 14px;
}

/* applied only in the first page  */
.no-subtitle {
  color: #fff;
  font-size: 14px;
}

.title,
.subtitle {
  margin: 0;
}

@media all and (min-width: 630px) {
  .header-container {
    align-items: flex-end;
  }

  .title {
    font-size: 26px;
  }

  .subtitle {
    font-size: 16px;
  }
}

@media all and (min-width: 860px) {
  .header-container {
    margin-top: 0px;
  }
}
