@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
body {
  margin: 0px;
  padding: 0px;
  background-color: var(--background-color);
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  overflow-x: hidden;
}

#root {
  margin: auto;
  /* background: blue !important; */
}

.responsive-image {
  width: 100%;
  height: auto;
}

iframe {
  width: 100%;
  height: 72vh;
  border: none;
}

/* @media screen and (max-width: 860px) {
  iframe{
    height: 70vh;
  }
}

@media screen and (max-width: 400px) {
  iframe{
    height: 60vh;
  }
}

@media screen and (max-height: 800px) {
  iframe{
    height: 68vh;
  }
} */
:root {
  --max-width: 1300px;
  --main-color: #33426c;
  --background-color: #ffffff;
  --app-container-color: #ffffff;
  --yellow: #ffed00;
}

#root {
  width: auto;
  max-width: 1300px;
  max-width: var(--max-width);
  height: auto;
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
}

#page-header {
  display: flex;
  flex-flow: row wrap;
}

#logo-section {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.image-wrapper {
  /*background: red;*/
  margin-right: 25px;
}

#lasitek-logo {
  width: 100px;
  height: auto;
}

.main-title {
  font-size: 18px;
  /*background: green;*/
  color: #33426c;
  color: var(--main-color);
  margin: 0;
  margin-top: 6px;
}

.horizontal-line {
  margin-top: 16px;
  width: 100%;
  height: 2px;
  background: #949494;
}

#nav-buttons {
  /*background: purple;*/
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1 1 100%;
}

#btn-menu {
  display: none;
}

#main-content {
  position: relative;
  width: auto;
  margin-top: 32px;
}

.btn-logo {
  /*background: purple;*/
  cursor: pointer;
  z-index: 15;
  position: absolute;
  top: -5px;
  left: 5px;
  transition: all 0.5s;
}

#show-hide-button {
  /*border: 1px solid var(--main-color);*/
  padding: 8px 10px 8px 4px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#show-hide-button[data-edit-text]::after {
  content: attr(data-edit-text);
  width: 120px;
  font-size: 14px;
  position: absolute;
  top: 7px;
  left: 40px;
  color: #33426c;
  color: var(--main-color);
}

#wrap-arrow {
  width: 30px;
  height: 30px;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#arrow {
  width: 10px;
  height: 10px;
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  transform: rotate(45deg);
  border-radius: 15%;
  border-color: #fcba03;
}

section.parameters {
  position: absolute;
  top: 0px;
  left: -110vw;
  width: 100vw;
  height: 87vh;
  z-index: 10;
  transition: all 0.3s;
  background-color: white;
}

#model-container {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 50px;
  left: 0px;
  /*background: black;*/
}

#btn-menu:checked ~ * section.parameters {
  left: 0px;
  height: auto;
  transition: all 0.5s;
}

#btn-menu:checked ~ * .btn-logo {
  left: calc(100vw - 70px);
  transition: all 0.5s;
}

#btn-menu:checked ~ * #arrow {
  transform: none;
  border: none;
}

#btn-menu:not(:checked) ~ * #icon-3d {
  display: none;
}

#btn-menu:checked ~ * #show-hide-button::after {
  content: none;
}

#btn-menu:checked ~ * #wrap-arrow {
  display: none;
}

#btn-menu:checked ~ * #show-hide-button[data-see3d-text]::before {
  content: attr(data-see3d-text);
  text-align: center;
  width: 150px;
  font-size: 13px;
  position: absolute;
  top: 10px;
  right: 38px;
  color: #33426c;
  color: var(--main-color);
}


@media all and (min-width: 600px) {
  #lasitek-logo {
    width: 115px;
  }
  .main-title {
    margin-top: 10px;
    font-size: 20px;
  }
}

@media all and (min-width: 800px) {
  #lasitek-logo {
    width: 125px;
  }

  #nav-buttons {
    margin-top: 9px;
    flex: auto;
  }

  #logo-section {
    justify-content: flex-start;
    flex: auto;
  }
}

@media all and (min-width: 860px) {
  .btn-logo {
    display: none;
  }

  #main-content,
  #model-container,
  section.parameters {
    position: static;
  }

  section.parameters {
    width: 35%;
    height: 70vh;
    transition: none;
    background-color: #33426c;
    /* background-color: red; */
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
  }

  #model-container {
    width: 65%;
    top: 0px;
  }

  #main-content {
    display: flex;
    flex-direction: row;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  border-bottom: solid;
}

.no-margin > * {
  margin: 0px;
}

.button {
  opacity: 1;
  color: #ffed00;
  cursor: pointer;
  margin: 20px 20px 0px 20px;
  padding: 5px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.color1 {
  background: #33426c;
  background: var(--main-color);
}

.color2 {
  background: #707070 0% 0% no-repeat padding-box;
}

.text-color1 {
  color: #949494;
  font-size: 15px;
  cursor: text;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-placeholder {
  background: #eeeeee;
  border-radius: 10px;
  height: 90px;
  width: 90px;
}

.feedback-message {
  color: red;
  font-size: 12.4px;
  margin-left: 15px;
  margin-top: 0px;
}

.invalid-group-input {
  border: 1px solid red;
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 10px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.next-button {
  max-width: 380px;
  height: 30px;
  margin: auto;
}

.button-triangle--next {
  margin-left: 3px;
  width: 10px;
  height: 10px;
  border-top: 3px solid #ffed00;
  border-right: 3px solid #ffed00;
  transform: rotate(45deg);
  border-radius: 15%;
}
.mobile-button {
  position: fixed;
  bottom: 15px;
  left: 50%;
  z-index: 11;
  min-width: 90vw;
  margin-left: -45vw;
}

.desktop-button {
  display: none !important;
}

.bottom-data {
  display: flex;
  max-width: 380px;
  margin: auto;
  margin-top: 15px;
  height: 40px;
}

.floating-button {
  display: flex;
  margin-top: 15px;
}

.shopping-cart,
.back-button {
  background: #4d4d4d;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.button-triangle--back--desktop,
.button-triangle--back {
  width: 10px;
  height: 10px;
  border-bottom: 3px solid #ffed00;
  border-left: 3px solid #ffed00;
  transform: rotate(45deg);
  border-radius: 15%;
}

.button-triangle--back {
  margin-top: 3px;
}

.cost-container {
  background: #33426c;
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar,
.total {
  color: #ffed00;
}

.bar {
  margin-left: 10px;
  margin-right: 10px;
}

.eur,
.cost {
  color: white;
}

.eur {
  margin-left: 5px;
}

.app-container {
  background-color: white;
  padding-bottom: 20px;
}

.footer {
  display: none;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 20px;
  padding-left: 130px;
  padding-right: 130px;
  align-items: center;
}

.footer-cost {
  display: flex;
  border-bottom: 1px solid #ffed00;
  border-bottom: 1px solid var(--yellow);
}

.footer-cost::after {
  content: '€';
  color: #ffed00;
  color: var(--yellow);
}

.add-to-cart,
.total-desktop {
  color: white;
  text-transform: uppercase;
}

.add-to-cart {
  cursor: pointer;
  border: 2px solid #ffed00;
  border: 2px solid var(--yellow);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: all 0.5s;
}

.add-to-cart:hover {
  background-color: #ffed00;
  background-color: var(--yellow);
}

.add-to-cart::after {
  content: 'LISÄÄ OSTOSKORIIN';
  transition: all 0.5s;
}

.add-to-cart:hover::after {
  color: black;
  font-weight: bolder;
  transition: all 0.5s;
}

.cost-desktop {
  margin-left: 30px;
  color: #ffed00;
  color: var(--yellow);
  margin-right: 10px;
}

@media all and (min-width: 860px) {
  .bottom-data,
  .next-button {
    margin: 0;
    margin-top: 15px;
    max-width: none;
  }

  .footer {
    display: flex;
  }

  #root {
    background-color: #33426c;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
  }
  .app-container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
  }

  .mobile-button {
    display: none;
  }

  .desktop-button {
    display: flex !important;
  }

  .form-container {
    background-color: white;
  }
}

.loading {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.model-loading--container {
  width: 100%;
  height: 100%;
  position: relative;
}

div#parameters-container {
  padding: 45px 5px 0px 0px;
  /* background: blue; */
  max-width: 380px;
  min-height: 75vh;
  height: 100%;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 70px;
}

div.parameters-horizontal-line {
  height: 1px;
  width: 100%;
  background: #949494;
  margin-bottom: 20px;
  margin-top: 0px;
}

@media all and (min-width: 860px) {
  div#parameters-container {
    padding-top: 0px;
    margin: 0;
    height: calc(70vh - 30px - 16px - 9px);
    max-width: none;
    margin-bottom: 0px;
    min-height: 0;
    min-height: initial;
  }
}

h3.parameters-section--title {
  font-weight: normal;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.title {
  font-weight: lighter;
  color: var(--main-color);
  font-size: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.subtitle {
  color: #949494;
  font-size: 14px;
}

/* applied only in the first page  */
.no-subtitle {
  color: #fff;
  font-size: 14px;
}

.title,
.subtitle {
  margin: 0;
}

@media all and (min-width: 630px) {
  .header-container {
    align-items: flex-end;
  }

  .title {
    font-size: 26px;
  }

  .subtitle {
    font-size: 16px;
  }
}

@media all and (min-width: 860px) {
  .header-container {
    margin-top: 0px;
  }
}

.templates-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
}

.image-center {
    display: block;
    margin: auto;
    margin-top: 15px;
    cursor: pointer;
    width: 60%;
    height: auto;
}

.selected-image {
    border: solid var(--main-color);
}


.group-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.group-input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin: 12px;
}

.opening-dimensions{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


div.select-title--section {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  /*background: purple;*/
}

h4.select-title {
  margin: 0;
  margin-left: 5px;
  font-size: 15px;
  font-weight: normal;
}

div.color-box {
  width: 13px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  border: 2px solid black;
}

div.color-container {
  white-space: nowrap;
  /*background: purple;*/
}

div.color-name {
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
}

div.select-colors-box--container {
  margin-bottom: 0px;
}

div.text-input--box {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  padding: 5px;
}

div.text-input--container {
  margin-bottom: 8px;
}

div.title-section {
  display: flex;
  flex: 1 1;
}

h4.text-field--title {
  margin: 0;
  margin-left: 5px;
  font-size: 16px;
  font-weight: normal;
  color: gray;
}

div.text-input--section {
  display: flex;
  flex: 1 1;
}

input.text-input--field {
  flex: 1 1;
  outline: none;
  width: 0px;
  border: none;
  text-align: center;
  font-weight: bold;
}

h4.vertical-separator {
  margin: 0px;
  font-size: 16px;
  color: black;
  font-weight: normal;
}

h6.error-message {
  margin: 0;
  margin-top: 3px;
  color: red;
}

div.input-container {
  display: flex;
  align-items: center;
}

.custom-input {
  border-right: none;
  border-bottom: none;
  border-top: none;
  text-align: center;
}

.custom-input:focus-visible {
  outline: none;
}

div.mm {
  font-size: 12px;
  font-weight: bold;
}

.disabled > * {
  cursor: not-allowed !important;
  color: gray;
}

.placeholder {
  margin: 0 auto;
  width: 100%;
  background-color: #eee;
  border-radius: 10px;
}

@-webkit-keyframes movingGradient {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes movingGradient {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  border-radius: 10px;
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: movingGradient;
          animation-name: movingGradient;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 30px;
  position: relative;
}

.open-modal-button,
.close-button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  margin-right: 8px;
  padding: 0;
}

.close-button {
  color: gray;
  margin-right: 5px;
}

.close-button::after {
  position: absolute;
  content: '\d7';
  top: 5px;
  right: 10px;
  font-size: 30px;
  color: black;
}

.modal-c-root{
  text-align: center;
}

.modal-content{
  padding: 15px;
  width: 350px;
}



/* .close-button::before {
    position: absolute;
    content: 'close';
    font-size: 10px;
    color: black;
  } */

