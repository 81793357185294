.templates-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
}

.image-center {
    display: block;
    margin: auto;
    margin-top: 15px;
    cursor: pointer;
    width: 60%;
    height: auto;
}

.selected-image {
    border: solid var(--main-color);
}

