.group-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.group-input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin: 12px;
}

.opening-dimensions{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

