.open-modal-button,
.close-button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  margin-right: 8px;
  padding: 0;
}

.close-button {
  color: gray;
  margin-right: 5px;
}

.close-button::after {
  position: absolute;
  content: '\d7';
  top: 5px;
  right: 10px;
  font-size: 30px;
  color: black;
}

.modal-c-root{
  text-align: center;
}

.modal-content{
  padding: 15px;
  width: 350px;
}



/* .close-button::before {
    position: absolute;
    content: 'close';
    font-size: 10px;
    color: black;
  } */
